<template>
    <div class="ml-10 mr-10">
        <TitleBar v-if="this.$route.params.uuid === 'new'" :loading="loading">{{ $t("transfers.new") }}</TitleBar>
        <TitleBar v-else
                  :disabled="loading"
                  :loading="loading"
                  :menu="[{text: this.$t('transfers.delete'), callback: deleteElement, class: 'red--text'}]"
        >{{ $t("transfers.edit") }}
        </TitleBar>

        <Tags v-if="this.$route.params.uuid !== 'new'" :element="$route.params.uuid"></Tags>

        <v-form @submit.prevent="formSubmit">

            <v-row>
                <v-col cols="12" md="4">
                    <v-autocomplete
                        v-model="account_from"
                        :disabled="loading"
                        :error-messages="accountFromErrors"
                        :items="accounts"
                        :label="$t('transfers.account_from')"
                        item-text="name"
                        item-value="uuid"
                        outlined
                        @blur="$v.account_from.$touch()"
                        @input="$v.account_from.$touch()"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                    <v-autocomplete
                        v-model="account_to"
                        :disabled="loading"
                        :error-messages="accountToErrors"
                        :items="accounts"
                        :label="$t('transfers.account_to')"
                        item-text="name"
                        item-value="uuid"
                        outlined
                        @blur="$v.account_to.$touch()"
                        @input="$v.account_to.$touch()"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                    <DatePicker
                        v-model="transfer_date"
                        :disabled="loading"
                        :error-messages="dateErrors"
                        :label="$t('transfers.date')"
                        @blur="$v.transfer_date.$touch()"
                        @input="$v.transfer_date.$touch()"
                    ></DatePicker>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="amount"
                        :disabled="loading"
                        :error-messages="amountErrors"
                        :label="$t('transfers.amount')"
                        :suffix="currency_code"
                        outlined
                        @blur="$v.amount.$touch()"
                        @input="$v.amount.$touch()"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select
                        v-model="currency_code"
                        :disabled="loading"
                        :error-messages="currencyErrors"
                        :items="currencies"
                        :label="$t('transfers.currency')"
                        outlined
                        @blur="$v.currency_code.$touch()"
                        @input="$v.currency_code.$touch()"
                    ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="currency_rate"
                        :disabled="loading"
                        :label="$t('transfers.currency_rate')"
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="8">
                    <v-textarea
                        v-model="description"
                        :disabled="loading"
                        :label="$t('transfers.description')"
                        auto-grow
                        outlined
                        rows="3"
                    ></v-textarea>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="reference"
                        :disabled="loading"
                        :label="$t('transfers.reference')"
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-btn
                :loading="loading"
                color="secondary"
                elevation="2"
                type="submit"
            >{{ $route.params.uuid === "new" ? $t("transfers.add") : $t("transfers.update") }}
            </v-btn>
        </v-form>

        <Attachments v-if="this.$route.params.uuid !== 'new'" :element="$route.params.uuid"></Attachments>

        <!--        <v-btn-->
        <!--            v-if="this.$route.params.uuid !== 'new'"-->
        <!--            :loading="loading"-->
        <!--            class="mt-10"-->
        <!--            color="error"-->
        <!--            elevation="2"-->
        <!--            @click.prevent="deleteElement"-->
        <!--        >{{ $t("transfers.delete") }}-->
        <!--        </v-btn>-->

    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Accounting from "../../helpers/Accounting";
import Tags from "../../components/Tags";
import Attachments from "../../components/Attachments";
import Utils from "../../helpers/Utils";
import Currencies from "../../helpers/Currencies";
import DatePicker from "../../components/DatePicker";
import TitleBar from "@/components/TitleBar.vue";

export default {
    name: "Details",
    components: {TitleBar, Attachments, Tags, DatePicker},
    async mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.transfers"),
                to: "/transfers",
                exact: true
            },
            {
                text: this.$route.params.uuid === "new" ? this.$t("transfers.new") : this.$t("transfers.edit"),
                to: "/transfers/" + this.$route.params.uuid,
                exact: true
            }
        ]);

        this.loading = true;
        let accounts = await Accounting.get("/accounts?fields=name,uuid&per_page=-1&page=1").catch(() => {
        });
        this.accounts = accounts.data.accounts;
        this.loading = false;

        if (this.$route.params.uuid !== "new") {
            this.loading = true;
            Accounting.get("/transfers/" + this.$route.params.uuid)
                .then(response => {
                    const data = response.data;
                    this.amount = data.amount;
                    this.currency_code = data.currency_code;
                    this.currency_rate = data.currency_rate;
                    this.description = data.description;
                    this.account_from = data.account.from;
                    this.reference = data.reference;
                    this.account_to = data.account.to;
                    this.transfer_date = data.transfer_date;
                    this.loading = false;
                }).catch(error => {
                if (error.response) {
                    let errors = [];
                    for (const errorElement of error.response.data.errors) {
                        errors.push({
                            type: "error",
                            text: errorElement
                        });
                    }
                    this.$store.commit("SET_ALERTS", errors);
                } else if (error.request) {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: this.$t("api.no_response")
                    }]);
                } else {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: error.message
                    }]);
                }
                this.loading = false;
            });
        }
    },
    data: () => {
        return {
            loading: false,
            amount: null,
            currency_code: null,
            currencies: Currencies.get(),
            currency_rate: null,
            description: null,
            account_from: null,
            reference: null,
            account_to: null,
            transfer_date: null,
            accounts: []
        };
    },
    methods: {
        deleteElement() {
            this.$swal({
                title: this.$t("transfers.delete_title"),
                text: this.$t("transfers.delete_text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("transfers.delete_yes"),
                cancelButtonText: this.$t("transfers.delete_no"),
                confirmButtonColor: Utils.getColor(this, "error"),
                cancelButtonColor: Utils.getColor(this, "primary")
            }).then(result => {
                if (result.isConfirmed) {
                    this.loading = true;
                    this.$store.commit("SET_ALERTS", []);
                    Accounting.delete("/transfers/" + this.$route.params.uuid)
                        .then(() => {
                            this.$store.commit("SET_ALERTS", [{
                                type: "success",
                                text: this.$t("transfers.deleted")
                            }]);
                            this.loading = false;
                            this.$router.push("/transfers");
                        })
                        .catch(error => {
                            if (error.response) {
                                let errors = [];
                                for (const errorElement of error.response.data.errors) {
                                    errors.push({
                                        type: "error",
                                        text: errorElement
                                    });
                                }
                                this.$store.commit("SET_ALERTS", errors);
                            } else if (error.request) {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: this.$t("api.no_response")
                                }]);
                            } else {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: error.message
                                }]);
                            }
                            this.loading = false;
                        });
                }
            });
        },
        formSubmit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;

                let url = "/transfers";

                if (this.$route.params.uuid !== "new") {
                    url = url + "/" + this.$route.params.uuid;
                }

                this.$store.commit("SET_ALERTS", []);

                Accounting.post(url, {
                    amount: this.amount,
                    currency_code: this.currency_code,
                    currency_rate: this.currency_rate,
                    description: this.description,
                    from: this.account_from,
                    reference: this.reference,
                    to: this.account_to,
                    transfer_date: this.transfer_date
                }).then(response => {

                    this.loading = false;
                    if (this.$route.params.uuid === "new") {
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("transfers.added")
                        }]);
                        this.$router.push("/transfers/" + response.data.uuid);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("transfers.updated")
                        }]);
                    }

                }).catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                    this.loading = false;
                });

            }
        }
    },
    mixins: [validationMixin],
    validations: {
        currency_code: {required},
        account_from: {required},
        account_to: {required},
        transfer_date: {required},
        amount: {required}
    },
    computed: {
        currencyErrors() {
            const errors = [];
            if (!this.$v.currency_code.$dirty) return errors;
            !this.$v.currency_code.required && errors.push(this.$t("transfers.currency_required"));
            return errors;
        },
        accountFromErrors() {
            const errors = [];
            if (!this.$v.account_from.$dirty) return errors;
            !this.$v.account_from.required && errors.push(this.$t("transfers.account_from_required"));
            return errors;
        },
        accountToErrors() {
            const errors = [];
            if (!this.$v.account_to.$dirty) return errors;
            !this.$v.account_to.required && errors.push(this.$t("transfers.account_to_required"));
            return errors;
        },
        dateErrors() {
            const errors = [];
            if (!this.$v.transfer_date.$dirty) return errors;
            !this.$v.transfer_date.required && errors.push(this.$t("transfers.date_required"));
            return errors;
        },
        amountErrors() {
            const errors = [];
            if (!this.$v.amount.$dirty) return errors;
            !this.$v.amount.required && errors.push(this.$t("transfers.amount_required"));
            return errors;
        }
    }
};
</script>

<style scoped>

</style>